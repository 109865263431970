import React, { FunctionComponent } from 'react';
import useInfiniteScroll, {
  useInfiniteScrollType,
} from 'hooks/useInfiniteScroll';

import PostItem from './PostItem';
import { PostListItemType } from '../../types/PostItem.types';
import { globalStyles } from '../../styles/GlobalStyle';
import styled from '@emotion/styled';

export type PostType = {
  node: {
    id: string;
    frontmatter: {
      title: string;
      summary: string;
      date: string;
      categories: string[];
      // thumbnail: {
      //   publicURL: string;
      // };
    };
  };
};

type PostListProps = {
  selectedCategory: string;
  posts: PostListItemType[];
  searchText: string;
};

const PostListWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledPostList = styled.div`
  width: 100%;
  max-width: var(--width-primary);
  display: flex;
  flex-flow: column;
  grid-gap: var(--inner-primary);
  margin: var(--pad-inner);

  @media (max-width: ${globalStyles.mobile}) {
  }
`;

const PostList: FunctionComponent<PostListProps> = function ({
  selectedCategory,
  posts,
  searchText,
}) {
  const { containerRef, postList }: useInfiniteScrollType = useInfiniteScroll(
    selectedCategory,
    posts,
    searchText,
  );

  return (
    <PostListWrapper>
      <StyledPostList ref={containerRef}>
        {postList.map(
          ({
            node: {
              id,
              fields: { slug },
              frontmatter,
            },
          }: PostListItemType) => (
            <PostItem {...frontmatter} link={slug} key={id} />
          ),
        )}
      </StyledPostList>
    </PostListWrapper>
  );
};

export default PostList;
