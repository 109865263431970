import React, { FunctionComponent, useEffect, useState } from 'react';

import SearchIcon from './svg/SearchIcon';
import { globalStyles } from '../styles/GlobalStyle';
import styled from '@emotion/styled';

type SearchInputProps = {
  onSearch: (searchText: string) => void;
  portfolio: boolean;
};

const SearchWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const StyledSearchBar = styled.div<{ searchFocus: boolean }>`
  width: 100%;
  max-width: var(--width-primary);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: ${(props) =>
    props.searchFocus ? 'none' : '0.8px solid var(--color-accent)'};
  margin: calc(var(--pad-inner) * 6) var(--pad-inner) var(--pad-inner);

  @media (max-width: ${globalStyles.mobile}) {
    margin: calc(var(--pad-inner) * 5) var(--pad-inner) var(--pad-inner);
    /* margin: var(--inner-secondary) var(--pad-inner); */
  }
`;

const SearchBar = styled.input<{ searchFocus: boolean }>`
  width: 100%;
  border: none;
  border-radius: 8px;
  font-size: var(--text-pad);
  color: ${(props) =>
    props.searchFocus ? 'var(--color-primary)' : 'var(--color-secondary)'};
  background-color: ${(props) =>
    props.searchFocus ? 'var(--color-secondary)' : 'transparent'};
  transition: background-color 0.6s ease, border 0.6s ease;
  padding: 12px 12px 12px 48px;
  outline: none;
  transition: all 0.3s ease-in-out;
  font-family: 'Pretendard', -apple-system;
  flex: 1;

  @media (max-width: ${globalStyles.mobile}) {
    font-size: 12px;
    height: 36px;
    padding: 12px 12px 12px 38px;
  }
`;

const IconWrapper = styled.div`
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  margin-left: 12px;
  /* 아이콘 클릭 방지 */
  pointer-events: none;

  @media (max-width: ${globalStyles.mobile}) {
    width: 14px;
  }
`;

const SearchInput: FunctionComponent<SearchInputProps> = ({
  onSearch,
  portfolio,
}) => {
  const [searchText, setSearchText] = useState<string>('');
  const [searchFocus, setSearchFocus] = useState<boolean>(false);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    if (event.target.value.length >= 1) {
      onSearch(event.target.value);
    } else {
      onSearch('');
    }
  };

  useEffect(() => {
    if (searchText.length >= 1) {
      setSearchFocus(true);
    } else {
      setSearchFocus(false);
    }
  }, [searchText]);

  useEffect(() => {
    if (portfolio === true) {
      setSearchFocus(true);
      setSearchText('Side Project');
    } else {
      setSearchFocus(false);
      setSearchText('');
    }
  }, [portfolio]);

  return (
    <SearchWrapper>
      <StyledSearchBar searchFocus={searchFocus}>
        <IconWrapper>
          <SearchIcon
            style={{
              stroke: searchFocus
                ? 'var(--color-primary)'
                : 'var(--color-accent)',
            }}
          />
        </IconWrapper>
        <SearchBar
          type="text"
          name="search"
          value={searchText}
          onChange={handleSearch}
          searchFocus={searchFocus}
          autoComplete="off"
        />
      </StyledSearchBar>
    </SearchWrapper>
  );
};

export default SearchInput;
