import React from 'react';

type TSearchIcon = {
  style: React.CSSProperties;
};

const SearchIcon = ({ style }: TSearchIcon) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 25 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <circle cx="15.3235" cy="9.94118" r="8.67647" />
      <line x1="1.14645" y1="24.8817" x2="9.61703" y2="16.4112" />
    </svg>
  );
};
export default SearchIcon;
