import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { Link } from 'gatsby';
import { PostFrontmatterType } from '../../types/PostItem.types';
import defaultImage from '../../posts/img/thumbnail/log-icon.jpg';
import { globalStyles } from '../../styles/GlobalStyle';
import styled from '@emotion/styled';

type PostItemProps = PostFrontmatterType & {
  link: string;
};

interface IAnimate {
  animate: boolean;
  width?: string;
}

const PostItemWrapper = styled.div`
  display: flex;
  flex-wrap: row wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--inner-secondary-3) 0;

  @media (max-width: ${globalStyles.mobile}) {
    padding: var(--pad-inner-1) 0;
  }
`;

const PostItemContent = styled(Link)`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  /* height: var(--height-primary); */
  /* padding: 3px 0; */
  white-space: 'nowrap';

  @media (max-width: ${globalStyles.mobile}) {
    /* height: var(--height-secondary); */
  }
`;

// const ThumbnailImage = styled.img`
//   width: 100px;
//   height: 100px;
//   border-radius: 0.5px;
//   object-fit: cover;
// `;

const StyledTitle = styled.div`
  width: fit-content;
  /* display: flex; */
`;

const Title = styled.div<IAnimate>`
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  overflow-wrap: break-word;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-size: var(--text-title);
  font-weight: 500;
  position: relative;

  ::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: ${({ animate, width }) => (animate && width ? width : '0px')};
    height: 1px;
    background-color: var(--color-accent);
    transition: width 0.3s ease-in-out;
  }

  @media (max-width: ${globalStyles.mobile}) {
    font-size: var(--text-title-pad);
  }
`;

// const Date = styled.div`
//   font-size: 14px;
//   font-weight: 400;
//   opacity: 0.7;
//   margin-top: 6px;
//   opacity: 0.5;
// `

const Category = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const CategoryItem = styled.div<IAnimate>`
  margin-right: 6px;
  padding: var(--category-gap);
  border-radius: var(--rounded-normal);
  background-color: ${({ animate }) =>
    animate ? 'trasparent' : 'var(--color-secondary)'};
  font-size: var(--text-category);
  color: ${({ animate }) =>
    animate ? 'var(--color-secondary)' : 'var(--color-primary)'};
  border: 0.8px solid
    ${({ animate }) =>
      animate ? 'var(--color-secondary)' : 'var(--color-secondary)'};
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
`;

const Summary = styled.div`
  display: flex;
  font-size: var(--text-pad);
  opacity: 0.8;
  color: var(--color-accent-1);
`;

const PostItem: FunctionComponent<PostItemProps> = function ({
  title,
  categories,
  summary = '',
  // thumbnail: { publicURL },
  link,
}) {
  const [animate, setAnimate] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number>(0);
  const titleRef = useRef<HTMLDivElement>(null);

  const handleMouseEnter = () => {
    setAnimate(true);
  };

  const handleMouseLeave = () => {
    setAnimate(false);
  };

  // if (!publicURL) return null;

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowWidth(window.innerWidth);

        if (titleRef.current) {
          titleRef.current.style.width = window.matchMedia(
            `(max-width: ${globalStyles.mobile})`,
          ).matches
            ? '100%'
            : 'auto';
        }
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }

    return undefined;
  }, []);

  useEffect(() => {
    if (titleRef.current) {
      const parentContainer = titleRef.current.parentElement;
      if (parentContainer) {
        const containerWidth = parentContainer.offsetWidth;
        const titleWidth = titleRef.current.scrollWidth;
        if (titleWidth > containerWidth) {
          titleRef.current.style.width = `${containerWidth}px`;
        } else {
          titleRef.current.style.width = 'auto'; // Allow full width if not truncated
        }
      }
    }
  }, [title, windowWidth]);

  return (
    <PostItemWrapper>
      <PostItemContent
        to={link}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <StyledTitle>
          <Title
            animate={animate}
            ref={titleRef}
            width={`${titleRef.current?.getBoundingClientRect().width}px`}
          >
            {title}
          </Title>
        </StyledTitle>
        <Summary style={{ padding: summary === '' ? '6px 0' : '12px 0 14px' }}>
          {summary}
        </Summary>
        <Category>
          {categories.map((category) => (
            <CategoryItem key={category} animate={animate}>
              {category}
            </CategoryItem>
          ))}
        </Category>
      </PostItemContent>
      {/* <ThumbnailImage
        src={publicURL}
        srcSet={`${publicURL} 100w`}
        alt="ThumbnailImage"
      /> */}
    </PostItemWrapper>
  );
};

export default PostItem;
